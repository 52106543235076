@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 255, 255, 255;
        --background-2: 240, 243, 244;

        --foreground: 64, 81, 93;
        --link: 0, 149, 250;

        --sidebar: 249, 250, 251;
        --sidebar-foreground: 64, 81, 93;

        --neutral: 247, 247, 247;
        --neutral-foreground: 96, 96, 96;

        --popover: 255, 255, 255;
        --popover-foreground: 15, 23, 41;
        --popover-border: 225, 231, 234;

        --card: 255, 255, 255;
        --card-foreground: 15, 23, 42;

        --border: 239, 243, 244;

        --input-border: 234, 238, 241;
        --input-background: 247, 251, 252;

        --primary: 29, 35, 42;
        --primary-foreground: 255, 255, 255;

        --muted: 245, 246, 248;
        --muted-foreground: 100, 117, 139;

        --secondary: 244, 246, 246;
        --secondary-foreground: 83, 100, 113;

        --tertiary: 222, 224, 224;
        --tertiary-foreground: 83, 100, 113;

        --test: rgb(244, 246, 246);
        --test1: rgb(0, 153, 231);

        --accent: 0, 149, 250;
        --accent-light: 0, 153, 231;
        --accent-foreground: 255, 255, 255;

        --success: 0, 171, 81;
        --success-foreground: 255, 255, 255;

        --destructive: 250, 91, 78; /*250, 91, 78*/
        --destructive-foreground: 255, 255, 255;

        --ring: 29, 155, 240;

        --radius: 0.5rem;
    }

    .landing {
        --background: 36, 41, 46;
        --foreground: 226, 228, 232;
        --border: 43, 56, 74;

        --sidebar: 43, 56, 74;
        --sidebar-foreground: 255, 255, 255;

        --popover: 43, 56, 74;
        --popover-foreground: 255, 255, 255;
        --popover-border: 43, 56, 74;

        --input-background: 47, 54, 62; /*51, 53, 56;*/
        --input-border: 57, 66, 76;

        --accent: 3, 103, 215;

        --destructive: 217, 81, 81;
        --destructive-foreground: 226, 228, 232;
    }

    .dark {
        --background: 36, 41, 46;
        --background-2: 47, 51, 55;

        --foreground: 226, 228, 232;
        --link: 116, 173, 239;

        --sidebar: 31, 36, 40;
        --sidebar-foreground: 226, 228, 232;

        --neutral: 247, 247, 247;
        --neutral-foreground: 96, 96, 96;

        --popover: 42, 47, 51;
        --popover-foreground: 226, 228, 232;
        --popover-border: 71, 73, 77;

        --card: 42, 47, 51;
        --card-foreground: 191, 195, 200;

        --border: 31, 36, 40;

        --input-background: 47, 54, 62; /*51, 53, 56;*/
        --input-border: 57, 66, 76;

        --primary: 209, 211, 213;
        --primary-foreground: 36, 41, 46;

        --muted: 53, 57, 61;
        --muted-foreground: 191, 195, 200;

        --secondary: 244, 246, 246;
        --secondary-foreground: 83, 100, 113;

        --tertiary: 222, 224, 224;
        --tertiary-foreground: 83, 100, 113;

        --test: rgb(244, 246, 246);
        --test1: rgb(0, 153, 231);

        --accent: 3, 103, 215;
        --accent-light: 0, 153, 231;
        --accent-foreground: 226, 228, 232;

        --success: 36, 134, 59;
        --success-foreground: 226, 228, 232;

        --destructive: 217, 81, 81;
        --destructive-foreground: 226, 228, 232;

        --ring: 29, 155, 240;

        --radius: 0.5rem;
    }
}

@layer base {
    html {
        font-size: 14px;
        font-weight: 430;
    }
    body {
        @apply bg-background text-foreground;
    }
    path,
    circle,
    ellipse,
    line,
    polygon,
    polyline,
    rect {
        stroke-width: inherit;
    }

    .io-icon {
        width: 24px;
        height: 24px;
        stroke-width: 42;
    }
    .lucide-icon {
        stroke-width: 2;
    }
}

@layer utilities {
    .h-screen {
        height: 100vh;
        height: 100dvh;
    }
    .sticky {
        position: -webkit-sticky;
    }
}